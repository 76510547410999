/*
 * Constants
 */

const EVENT_MOUSEENTER_DATA_API = `mouseenter`
const EVENT_MOUSELEAVE_DATA_API = `mouseleave`
const EVENT_TOUCHSTART_DATA_API = `touchstart`

const CLASS_NAME_DISPLAYED = 'displayed'
const CLASS_NAME_ACTIVE = 'active'

const SELECTOR_SPEED_DIAL = '.speed-dial'
const SELECTOR_SPEED_DIAL_BUTTON = '.speed-dial-button'
const SELECTOR_LAST_ITEM = '.speed-dial-list-item:last-child'

/*
 * Utils
 */

const CALLBACKS = []

function onDomContentLoaded(callback) {
    if (document.readyState === 'loading') {
        CALLBACKS.push(callback)
        document.addEventListener('DOMContentLoaded', () => {
            CALLBACKS.forEach((callback) => callback())
        })
    } else {
        callback()
    }
}

function getTransitionDurationFromElement(element) {
    let { transitionDuration, transitionDelay } = window.getComputedStyle(element)

    const floatDuration = Number.parseFloat(transitionDuration)
    const floatDelay = Number.parseFloat(transitionDelay)

    if (Number.isNaN(floatDuration) && Number.isNaN(floatDelay)) {
        return 0
    }

    transitionDuration = transitionDuration.split(',')[0]
    transitionDelay = transitionDelay.split(',')[0]

    return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * 1000
}


function executeAfterTransition(
    callback,
    element,
    waitForTransition = true
) {
    if (!waitForTransition) {
        callback()
        return
    }

    const durationPadding = 5
    const emulatedDuration = getTransitionDurationFromElement(element) + durationPadding

    let called = false

    const handler = (event) => {
        const { target } = event
        // Protect from other events bubbling
        if (target !== element) {
            return
        }

        called = true
        element.removeEventListener('transitionend', handler)
        callback()
    }

    element.addEventListener('transitionend', handler)
    setTimeout(() => {
        if (!called) {
            element.dispatchEvent(new Event('transitionend'))
        }
    }, emulatedDuration)
}

/*
 * Class
 */

class SpeedDial {
    constructor(element) {
        this._element = element
        this._element._speedDialInstance = this

        this._isActive = false

        this._element.addEventListener(EVENT_MOUSELEAVE_DATA_API, () => {
            this.hide()
        })
    }

    toggle() {
        if (this._isActive) {
            this.hide()
        } else {
            this.show()
        }
    }

    show() {
        if (this._isActive) {
            return
        }

        this._element.classList.add(CLASS_NAME_DISPLAYED)
        this._element.offsetHeight // reflow
        this._element.classList.add(CLASS_NAME_ACTIVE)
        this._isActive = true
    }

    hide() {
        if (!this._isActive) {
            return
        }

        const lastItem = this._getLastItem()

        if (lastItem === null) {
            return
        }

        executeAfterTransition(() => {
            if (this._isActive) {
                return
            }

            this._element.classList.remove(CLASS_NAME_DISPLAYED)
        }, lastItem)

        this._element.classList.remove(CLASS_NAME_ACTIVE)
        this._isActive = false
    }

    _getLastItem() {
        const el = this._element.querySelector(SELECTOR_LAST_ITEM)

        return el instanceof HTMLElement ? el : null
    }
}

/*
 * Implementation
 */

const handleShow = (event) => {
    const delegateTarget = event.target && event.target.closest(SELECTOR_SPEED_DIAL_BUTTON)

    if (!delegateTarget) {
        return
    }

    const dial = delegateTarget.closest(SELECTOR_SPEED_DIAL)

    if (!dial) {
        return
    }

    if (event.type === 'mouseenter') {
        (dial._speedDialInstance || new SpeedDial(dial)).show()
    } else {
        (dial._speedDialInstance || new SpeedDial(dial)).toggle()
    }
}

const handleClickOutside = (event) => {
    if (!event.target) {
        return
    }

    const closestDial = event.target && event.target.closest(SELECTOR_SPEED_DIAL)

    if (closestDial !== null) return

    const activeDial = document.querySelector(`${SELECTOR_SPEED_DIAL}.${CLASS_NAME_ACTIVE}.${CLASS_NAME_DISPLAYED}`)

    if (activeDial) {
        const instance = activeDial._speedDialInstance

        if (instance !== null) {
            instance.hide()
        }
    }
}

onDomContentLoaded(() => {
    const buttons = document.querySelectorAll(SELECTOR_SPEED_DIAL_BUTTON)

    for (const button of buttons) {
        if ('ontouchstart' in document.documentElement) {
            button.addEventListener(EVENT_TOUCHSTART_DATA_API, handleShow)
            document.addEventListener(EVENT_TOUCHSTART_DATA_API, handleClickOutside)
        } else {
            button.addEventListener(EVENT_MOUSEENTER_DATA_API, handleShow)
        }
    }
})
